import global from './global.js'
import paginator from './paginator'
import datatable from './datatable'
import galleria from './galleria'
import select from './select'

export default {
  global,
  paginator,
  datatable,
  galleria,
  select
}
